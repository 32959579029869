const SidebarData = [
    {
        label: "VISÕES",
        isMainMenu: true,
    },
    // {
    //     label: "Dashboard Principal",
    //     icon: "mdi mdi-home-variant-outline",
    //     url: "/dashboard",
    //     isHasArrow: true,
    //     // issubMenubadge: true,
    //     // bgcolor: "bg-primary",
    //     // badgeValue: "3"
    // },
    {
        label: "Visão Executiva",
        icon: "mdi mdi-eye-outline",
        isHasArrow: true,
        url: "/executive-vision",
    },
    {
        label: "SEO",
        icon: "mdi mdi-magnify",
        isHasArrow: true,
        url: "/seo",
        permission: "uploads_list_crm_leads",
    },
    {
        label: "CAMPANHAS",
        isMainMenu: true,
    },
    {
        label: "Google",
        icon: "mdi mdi-google",
        subItem: [
            { sublabel: "Google Analytics", link: "/campaigns/google/analytics", permission: "campaigns_google_analytics" },
            { sublabel: "Google Ads.", link: "/campaigns/google/ads", permission: "campaigns_google_ads" },
        ],
        permission: ["campaigns_google_ads", "campaigns_google_analytics"],
    },
    {
        label: "Meta",
        icon: "mdi mdi-facebook",
        subItem: [
            { sublabel: "Instagram", link: "/campaigns/meta/instagram" },
            { sublabel: "Facebook", link: "/campaigns/meta/facebook" },
            { sublabel: "Meta Ads.", link: "/campaigns/meta/ads", permission: "campaigns_meta_ads" },
        ],
        permission: ["campaigns_meta_ads"],
    },
    {
        label: "LinkedIn",
        icon: "mdi mdi-linkedin",
        subItem: [
            { sublabel: "LinkedIn", link: "/campaigns/linkedin" },
            { sublabel: "LinkedIn Ads.", link: "/campaigns/linkedin/ads", permission: "campaigns_linkedin_ads" },
        ],
        permission: ["campaigns_linkedin_ads"],
    },
    {
        label: "Press Release",
        icon: "mdi mdi-newspaper-variant-outline",
        isHasArrow: true,
        url: "/campaigns/press-release",
        permission: "campaigns_linkedin_ads",
    },
    {
        label: "CADASTROS",
        isMainMenu: true,
        permission: ["uploads_list_crm_leads"],
    },
    // {
    //     label: "CRM Leads",
    //     icon: "mdi mdi-file-upload-outline",
    //     isHasArrow: true,
    //     url: "/uploads/crm-sheet",
    //     permission: "uploads_list_crm_leads",
    // },
    {
        label: "Metas",
        icon: "mdi mdi-bullseye",
        isHasArrow: true,
        url: "/goals",
        permission: "uploads_list_crm_leads", // TODO
    },
    {
        label: "ADMINISTRAÇÃO",
        isMainMenu: true,
        permission: ["users_list", "permissions_list"],
    },
    {
        label: "Usuários",
        icon: "mdi mdi-account-group-outline",
        isHasArrow: true,
        url: "/users",
        permission: "users_list",
    },
    {
        label: "Permissões",
        icon: "mdi mdi-lock-open-alert-outline",
        isHasArrow: true,
        url: "/permissions",
        permission: "permissions_list",
    },
]
export default SidebarData;