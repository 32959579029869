import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { defaultTitle } from "../../constants/layout";
import { Loading } from "../../components/Common/Loading";

import API from "../../services/api";
import { aAlert, formatDatetime, getUrlParam, removeUrlParam } from "../../helpers/general_helper";
import { PermissionOrDie } from "../../helpers/auth_helper";
import { Button } from "../../components/Common/Button";
import { BACKEND_UPLOADS } from "../../constants/env";
import { NoResults } from "../../components/Common/NoResults";
import { Link } from "react-router-dom";

const UploadsCrmLeadSheetPage = () => {
  document.title = `Importar Planilha de Leads | ${defaultTitle}`;
  PermissionOrDie('uploads_list_crm_leads');

  const [is_loading, setis_loading] = useState(true);
  const [is_loading_post, setis_loading_post] = useState(false);

  const [data, setdata] = useState([]);
  useEffect(() => {
    API.get("get-uploads/sheets-crm-lead")
    .then(({ data }) => {
        setdata(data);
    })
    .catch((error) => {
        aAlert(error?.response?.data?.message ?? error.message, "error");
    })
    .then(() => {
        setis_loading(false);
    });

    showSuccessMessages();
  }, []);

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: "",
    },
    validationSchema: Yup.object({
      file: Yup.mixed().test('isFile', 'Por favor, selecione o arquivo.', value => {
        if(value === undefined || value === "" || typeof value !== "string"){
          return false;
        }
        return value.toLowerCase().includes(".xls") || value.toLowerCase().includes(".xlsx");
      }),
    }),
    onSubmit: (values) => {
      setis_loading(true);
      sendFile();
    },
  });

  const sendFile = () => {
    setis_loading_post(true);
    
    const fileInput = document.getElementById('fileUpload');
    const formData = new FormData();
    formData.append('file', fileInput.files[0]);

    API.post("upload/crm-leads", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        window.location = '/uploads/crm-sheet?success=1';
      })
      .catch((error) => {
        setis_loading_post(false);
        aAlert(error?.response?.data?.message ?? error.message, "error");
      });
  }

  const showSuccessMessages = () => {
      switch(getUrlParam('success')){
          case "1":
              aAlert("Arquivo enviado com sucesso!", "success");
          break;
          default: break;
      }
      removeUrlParam("success");
  }

  const downloadFile = (fileName) => {
    API.get(`/download/${fileName}`, {
        responseType: 'blob',
    }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }).catch((error) => {
        console.error('Ocorreu um erro ao fazer o download do arquivo:', error);
    });
  }

  return (
    <React.Fragment>
      <Loading isLoading={is_loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Uploads"
            breadcrumbItem="CRM Leads"
          />
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="fileUpload">Arquivo</Label>
                          <Input
                            name="file"
                            placeholder=""
                            type="file"
                            accept=".xls, .xlsx"
                            className="form-control"
                            id="fileUpload"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.file || ""}
                            invalid={
                              validation.touched.file &&
                              validation.errors.file
                                ? true
                                : false
                            }
                          />
                          {validation.touched.file &&
                          validation.errors.file ? (
                            <FormFeedback type="invalid">
                              {validation.errors.file}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">Arquivo de Exemplo</Label>
                          <p className="card-title-desc">
                            <a href={`${BACKEND_UPLOADS}examples/example-crm-leads.xlsx`} target="_blank" rel="noreferrer">
                              <button type="button" className="btn btn-info">
                                <i className="mdi mdi-download"></i>&nbsp;
                                Baixar Arquivo
                              </button>
                            </a>
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md="12">
                <Button 
                  style={{ width: "100%" }}
                  text="Enviar Arquivo"
                  isLoading={is_loading_post}
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h5>Últimos Envios</h5>
                  <div className="table-responsive table-card mt-3 mb-1">
                    <table className="table align-middle table-nowrap" id="customerTable">
                        <thead className="table-light">
                            <tr>
                                <th className="sort" data-sort="file">Arquivo</th>
                                <th className="sort" data-sort="status">Status</th>
                                <th className="sort" data-sort="creator">Enviado Por</th>
                                <th className="sort" data-sort="date">Data</th>
                                <th className="sort text-center" data-sort="action" width={270}>Ações</th>
                            </tr>
                        </thead>
                        <tbody className="list form-check-all">
                            { data.map((data) => (
                              <tr key={data.id}>
                                  <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#{ data.id }</Link></td>
                                  <td className="file">{ data.file }</td>
                                  <td className="status">
                                    <span className={`badge badge-soft-${data.status.color}`}>{ data.status.name }</span>
                                  </td>
                                  <td className="creator">{ data.creatorName }</td>
                                  <td className="date">{ formatDatetime(data.created_at) }</td>
                                  <td>
                                    <div className="d-flex gap-2 justify-content-center align-center">
                                      <div className="edit">
                                        <a href="javascript:void(0)" onClick={() => downloadFile(data.download)} rel="noreferrer">
                                          <button className="btn btn-sm btn-dark edit-item-btn">Baixar Arquivo</button>
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                              </tr>
                            )) }
                        </tbody>
                    </table>
                    { !is_loading && data.length === 0 &&
                        <NoResults
                            title="Nenhum Resultado Encontrado!"
                            message="Não encontramos nenhuma planilha de leads cadastrada em nosso banco de dados."
                        />
                    }
                </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UploadsCrmLeadSheetPage;
