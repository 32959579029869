import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { defaultTitle } from "../../constants/layout";


const Error401Page = () => {
    document.title = `Sem Permissão | ${defaultTitle}`;
    return (
        <React.Fragment>
            <div className="my-5 pt-5">
                <div className="w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <div className="text-center">
                                    <div>
                                        <h1 className="display-2 error-text fw-bold">4<i className="ri-git-repository-private-fill align-bottom text-primary mx-1"></i>1</h1>
                                    </div>
                                    <div>
                                        <h4 className="text-uppercase mt-4">Ooops! Sem Permissão!</h4>
                                        <p>Você não tem permissão para acessar o recurso que está tentando. Se este for um erro de permissão, entre em contato com o administrador do sistema na sua empresa para que ele corrija.</p>
                                        <div className="mt-4">
                                            <Link to="/" className="btn btn-primary"><i className="ri-arrow-left-line align-bottom mr-2"></i>Voltar</Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </React.Fragment>
    );
}

export default Error401Page;