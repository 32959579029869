import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartBar2 = ({ data }) => {

  let labels1 = [];
  let values1 = [];
  for(let i = 0; i < data.values1.data.length; i++){
    labels1.push(data.values1.data[i].label);
    values1.push(data.values1.data[i].value);
  }

  let values2 = [];
  for(let i = 0; i < data.values2.data.length; i++){
    values2.push(data.values2.data[i].value);
  }

  const DashedLineData = {
    series: [{
      name: data.values1.title,
      type: 'column',
      data: values1
    },
    {
      name: data.values2.title,
      type: 'line',
      data: values2
    }],
    options: {
      chart: { height: 350, type: 'line', zoom: { enabled: !1 }, toolbar: { show: !1 } },
      stroke: {
        width: [0, 4]
      },
      title: { text: '' },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: labels1,
      xaxis: {
        type: 'datetime',
      },
      yaxis: [{
        title: {
          text: data.values1.title,
        },
        labels: {
          formatter: (value) => { return `${data.values1.labelPrx}${value}${data.values1.labelSuf}` },
        }
      },
      {
        opposite: true,
        title: {
          text: data.values2.title
        },
        labels: {
          formatter: (value) => { return `${data.values2.labelPrx}${value}${data.values2.labelSuf}` },
        }
      }]
    },
    grid: { borderColor: "#f1f1f1" },
  }

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
            <ReactApexChart
              options={DashedLineData.options}
              series={DashedLineData.series}
              type="line"
              height="380"
              className="apex-charts"
            />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}