const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutModeTypes = {
  LIGHTMODE : "light",
  DARKMODE : "dark",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
}

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
}

const leftSideBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
}

const defaultColors = {
  PURPLE: "#0b84a4",
  PINK: "#0b84a4",
}

const defaultTitle = "Dash4";
const defaultFooter = `© ${new Date().getFullYear()} Dash4 - Todos os Direitos Reservados`;
const defaultError = "Ops! Ocorreu um erro. Por favor, tente novamente mais tarde.";

export {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
  defaultColors,
  defaultTitle,
  defaultFooter,
  defaultError,
}
