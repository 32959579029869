import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartBar5 = ({ data }) => {

  let labels = [];
  let values1 = [];
  for(let i = 0; i < data.values1.data.length; i++){
    const lb = data.values1.data[i].label;
    if(labels[lb] === undefined){
      labels[lb] = lb;
    }
  }

  let values2 = [];
  for(let i = 0; i < data.values2.data.length; i++){
    const lb = data.values2.data[i].label;
    if(labels[lb] === undefined){
      labels[lb] = lb;
    }
  }

  let values3 = [];
  const hasAxis3 = data.values3;
  if(hasAxis3){
    for(let i = 0; i < data.values3.data.length; i++){
      const lb = data.values3.data[i].label;
      if(labels[lb] === undefined){
        labels[lb] = lb;
      }
    }
  }

  labels = Object.values(labels);

  for(let i = 0; i < labels.length; i++){
    const lb = labels[i];
    const value1 = data.values1.data.find(x => x.label === lb)?.value || 0;
    const value2 = data.values2.data.find(x => x.label === lb)?.value || 0;

    values1.push(value1);
    values2.push(value2);

    if(hasAxis3){
      const value3 = data.values3.data.find(x => x.label === lb)?.value || 0;
      values3.push(value3);
    }
  }

  const series = [];
  series.push({
    name: data.values1.title,
    data: values1
  });
  series.push({
    name: data.values2.title,
    data: values2
  });

  if(hasAxis3){
    series.push({
      name: data.values3.title,
      data: values3
    });
  }

  const DashedLineData = {
    series,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: false,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: labels,
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      }
    },
  }

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
            <ReactApexChart
              options={DashedLineData.options}
              series={DashedLineData.series}
              type="bar"
              height="380"
              className="apex-charts"
            />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}