import React from "react";
import { Container, Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

import { defaultTitle } from "../../constants/layout";

const ComingSoon = () => {
  document.title = `Em Breve | ${defaultTitle}`;
  return (
      <React.Fragment>
          <div className="my-5 pt-5">
              <div className="w-100">
                  <Container>
                      <Row className="justify-content-center">
                          <Col md={8} lg={6} xl={5}>
                              <div className="text-center">
                                  <div>
                                      <h1 className="display-2 error-text fw-bold">Aaaahhh <i className="mdi mdi-emoticon-sad-outline text-primary mx-1"></i></h1>
                                  </div>
                                  <div>
                                      <h4 className="text-uppercase mt-4">Em Breve!</h4>
                                      <p>Este recurso ainda não está configurado e disponível para o seu workspace.</p>
                                      <p>Estamos trabalhando para disponibilizar o mais rápido possível.</p>
                                      <div className="mt-4">
                                          <Link to="/" className="btn btn-primary"><i className="ri-arrow-left-line align-bottom mr-2"></i>Voltar</Link>
                                      </div>
                                  </div>
                              </div>
                          </Col>
                      </Row>
                  </Container>
              </div>
          </div>

      </React.Fragment>
  );
};

export default ComingSoon;
