import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  CardHeader,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { defaultTitle } from "../../constants/layout";
import { Loading } from "../../components/Common/Loading";

import API from "../../services/api";
import { aAlert, treatNumberFromPermissionName } from "../../helpers/general_helper";
import { Link } from "react-router-dom";
import { PermissionOrDie } from "../../helpers/auth_helper";
import { Button } from "../../components/Common/Button";

const PermissionsEditPage = () => {
  document.title = `Editar Permissão | ${defaultTitle}`;
  PermissionOrDie('permissions_edit');

  const { id } = useParams();

  const [is_loading, setis_loading] = useState(true);
  const [is_loading_post, setis_loading_post] = useState(false);
  const [invalid_permissions, setinvalid_permissions] = useState(false);
  const [data, setdata] = useState([]);

  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');

  const superAdminName = 'Super Admin';
  const [isAdminRole, setIsAdminRole] = useState(false);

  useEffect(() => {
    API.get(`roles/${id}`)
      .then(({ data }) => {
        setRoleName(data.name);
        setRoleDescription(data.description);
        setTimeout(() => {
          getPermissions(data.permissions);
        }, 1000);
        superAdmin(data.name);
      })
      .catch((error) => {
        setis_loading(false);
        aAlert(error?.response?.data?.message ?? error.message, "error");
      });
  }, []);

  const getPermissions = (permissions) => {
    API.get("roles/permissions")
      .then(({ data }) => {
        let newData = [];

        for(let i = 0; i < data.length; i++){
          let sub = [];
          let qtySelected = 0;
          for(let j = 0; j < data[i].length; j++){
            const subItem = data[i][j];
            const checked = j > 0 && permissions.find((ii) => ii.id === subItem.id) !== undefined;
            sub.push({
              ...subItem,
              is_selected: checked,
            });
            if(checked){
              qtySelected++;
            }
          }
          if((sub.length-1) === qtySelected){
            sub[0].is_selected = true;
          }
          newData.push(sub);
        }

        setdata(newData);
      })
      .catch((error) => {
        aAlert(error?.response?.data?.message ?? error.message, "error");
      })
      .then(() => {
        setis_loading(false);
      });
  }

  const superAdmin = (roleName) => {
    if(roleName !== superAdminName) {
      return;
    }

    setIsAdminRole(true);
    setTimeout(() => {
      let inputs = document.querySelectorAll('input');
      inputs.forEach(function(input) {
          input.disabled = true;
      });
    }, 200);
  }

  const onChangeCheckAll = (event) => {
    const { name, checked } = event.target;
    const selector = `input[type="checkbox"][name^="${name}-"]`;
    const checkboxes = document.querySelectorAll(selector);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }

  const onChangeCheckOne = (event) => {
    const { name } = event.target;
    const explode = name.split("-");
    const motherName = `${explode[0]}-${explode[1]}-${explode[2]}`;
    const selector = `input[type="checkbox"][name^="${motherName}-"]`;
    const checkboxes = document.querySelectorAll(selector);
    
    let total = 0;
    let chkd = 0;

    checkboxes.forEach((checkbox) => {
      if(checkbox.checked){ chkd++; }
      total++;
    });

    const checkbox = document.querySelector(`input[type="checkbox"][name="${motherName}"]`);
    if(total === chkd){
      checkbox.checked = true;
    }
    else {
      checkbox.checked = false;
    }

    setinvalid_permissions(false);
  }

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      role_name: roleName,
      role_description: roleDescription,
    },
    validationSchema: Yup.object({
      role_name: Yup.string().required("Por favor, informe o nome da permissão."),
      role_description: Yup.string().required("Por favor, informe a descrição da permissão."),
    }),
    onSubmit: (values) => {

      setinvalid_permissions(false);

      let permissions = [];
      const selector = `input[type="checkbox"][name^="ch-p-"]`;
      const checkboxes = document.querySelectorAll(selector);
      checkboxes.forEach((checkbox) => {
        const explode = checkbox.name.split("-");
        if(explode.length === 4 && checkbox.checked){
          permissions.push(checkbox.value);
        }
      });

      if(permissions.length === 0){
        setinvalid_permissions(true);
        return;
      }

      postPermission({
        name: values.role_name,
        description: values.role_description,
        permissions
      });

    },
  });

  const postPermission = (values) => {
    setis_loading_post(true);
    
    API.put(`roles/${id}`, {
      ...values
    })
      .then(() => {
        window.location = '/permissions?success=2';
      })
      .catch((error) => {
        setis_loading_post(false);
        aAlert(error?.response?.data?.message ?? error.message, "error");
      });
  }

  return (
    <React.Fragment>
      <Loading isLoading={is_loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Permissões"
            breadcrumbMother="Lista de Permissões"
            breadcrumbItem="Editar Permissão"
          />
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Nome</Label>
                          <Input
                            name="role_name"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role_name || ""}
                            invalid={
                              validation.touched.role_name &&
                              validation.errors.role_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.role_name &&
                          validation.errors.role_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.role_name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Descrição</Label>
                          <Input
                            name="role_description"
                            placeholder=""
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.role_description || ""}
                            invalid={
                              validation.touched.role_description &&
                              validation.errors.role_description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.role_description &&
                          validation.errors.role_description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.role_description}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {data.map((item, pIndex) => (
              <Row key={item[0].id}>
                <Col>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">{ treatNumberFromPermissionName(item[0].name) }</h4>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive table-card mb-1">
                        <table className="table align-middle table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={`ch-p-${pIndex}`}
                                    value="option"
                                    onChange={onChangeCheckAll}
                                    defaultChecked={item[0].is_selected}
                                  />
                                </div>
                              </th>
                              <th className="sort" data-sort="permission_name">
                                Permissão
                              </th>
                              <th className="sort" data-sort="permission_role_description">
                                Descrição
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            { item.map((p, index) => {
                              if(index === 0){ return <></>; }
                              return (
                                <tr key={p.id}>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name={`ch-p-${pIndex}-${index}`}
                                        value={`${p.id}`}
                                        onChange={onChangeCheckOne}
                                        defaultChecked={p.is_selected}
                                      />
                                    </div>
                                  </th>
                                  <td className="id" style={{ display: "none" }}>
                                    <Link to="#" className="fw-medium link-primary">
                                      { p.id }
                                    </Link>
                                  </td>
                                  <td className="permission_name">{ treatNumberFromPermissionName(p.name) }</td>
                                  <td className="permission_role_description">{ p.description }</td>
                                </tr>
                              );
                            }) }
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}
            <Row className="mb-5">
              { !isAdminRole &&
                <Col md="12">
                  { invalid_permissions &&
                    <div type="invalid" class="invalid-feedback" style={{ display: 'block', marginBottom: 20 }}>Por favor, informe pelo menos uma permissão para continuar.</div>
                  }
                  <Button 
                    style={{ width: "100%" }}
                    text="Salvar Alterações"
                    isLoading={is_loading_post}
                  />
                </Col>
              }
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PermissionsEditPage;
