import React, { useEffect } from "react";
import Routes from "./Routes/index";

// Import Scss
import './assets/scss/theme.scss';

// Fake Backend 
import { getLoggedinUser } from "./helpers/api_helper";
import { changeLayoutMode } from "./store/actions";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";


function App() {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = getLoggedinUser();
    if(user){
      dispatch(changeLayoutMode(user.layout_mode));
    }
  }, []);

  useEffect(() => {
    const currentHost = window.location.hostname;
    const developmentHosts = ['localhost', '127.0.0.1'];
    const productionHost = 'portal.dash4.com.br';

    if (!developmentHosts.includes(currentHost) && currentHost !== productionHost) {
      const newUrl = `https://${productionHost}${location.pathname}${location.search}${location.hash}`;
      window.location.replace(newUrl);
    }
  }, [navigate, location]);

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
