import React from "react";

import loadingGif from "../../../assets/images/loading.gif";

export const Button = ({ style, text, isLoading, color = 'primary' }) => {
  return (
    <button
      className={`btn btn-${color} waves-effect waves-light`}
      type="submit"
      style={{
        ...style,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
      disabled={isLoading}
    >
      {text}
      {isLoading &&
        <img
          src={loadingGif}
          alt="loading"
          width={18}
          height={18}
          style={{
            marginLeft: 5,
          }}
        />
      }
    </button>
  );
};
