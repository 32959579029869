import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  Collapse,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { defaultTitle } from "../../constants/layout";
import { Loading } from "../../components/Common/Loading";

import API from "../../services/api";
import { aAlert, formatDateToString, getFirstAndLastDateOfCurrentMonth } from "../../helpers/general_helper";
import { PermissionOrDie } from "../../helpers/auth_helper";
import { Chart } from "../../components/Common/Chart";
import { Button } from "../../components/Common/Button";
import classnames from "classnames";

const CampaignsGoogleAnalyticsPage = () => {
  document.title = `Google Analytics | ${defaultTitle}`;
  PermissionOrDie('campaigns_google_analytics');

  const [is_loading, setis_loading] = useState(true);
  const [showFilters, setshowFilters] = useState(false);

  const firstAndLastInitialDate = getFirstAndLastDateOfCurrentMonth();
  const [firstDate] = useState(firstAndLastInitialDate.first);
  const [lastDate] = useState(firstAndLastInitialDate.last);

  const t_showfilters = () => {
    setshowFilters(!showFilters);
  };

  const [data, setdata] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setis_loading(true);
    API.get("/campaigns/google-analytics", {
      params: {
        startDate: validation.values.filter_date_init,
        endDate: validation.values.filter_date_end,
        campaignId: -1,
      }
    })
    .then(({ data: result }) => {
      setdata(result.charts);
    })
    .catch((error) => {
      aAlert(error?.response?.data?.message ?? error.message, "error");
    })
    .then(() => {
      setis_loading(false);
    });
  }

  // Form validation
  const [currentDate] = useState(new Date());
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      filter_date_init: firstDate,
      filter_date_end: lastDate,
    },
    validationSchema: Yup.object({
      filter_date_init: Yup.date()
                           .required("Por favor, informe a data inicial.")
                           .max(currentDate, "A data inicial não pode ser maior do que a data atual")
                           .when('filter_date_end', (filter_date_end, schema) => {
                            return filter_date_end[0] ? schema.max(filter_date_end, 'A data inicial não pode ser maior do que a data final') : schema;
                           }),
      filter_date_end: Yup.date()
                          .required("Por favor, informe a data final.")
                          .min(Yup.ref('filter_date_init'), 'A data final deve ser maior ou igual à data inicial'),
    }),
    onSubmit: (values) => {
      getData();
    },
  });
  
  return (
    <React.Fragment>
      <Loading isLoading={is_loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Campanhas"
            breadcrumbMother="Google"
            breadcrumbItem="Google Analytics"
          />

          <Row>
            <Col className="mb-4">
              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: !showFilters }
                      )}
                      type="button"
                      onClick={t_showfilters}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="mdi mdi-filter-outline"></i>&nbsp;
                      { formatDateToString(validation.values.filter_date_init) } - { formatDateToString(validation.values.filter_date_end) }&nbsp;
                    </button>
                  </h2>

                  <Collapse
                    isOpen={showFilters}
                    className="accordion-collapse"
                  >
                    <div className="accordion-body">
                      <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Data Inicial</Label>
                              <Input
                                name="filter_date_init"
                                placeholder=""
                                type="date"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.filter_date_init || ""}
                                invalid={
                                  validation.touched.filter_date_init &&
                                  validation.errors.filter_date_init
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.filter_date_init &&
                              validation.errors.filter_date_init ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.filter_date_init}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Data Final</Label>
                              <Input
                                name="filter_date_end"
                                placeholder=""
                                type="date"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.filter_date_end || ""}
                                invalid={
                                  validation.touched.filter_date_end &&
                                  validation.errors.filter_date_end
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.filter_date_end &&
                              validation.errors.filter_date_end ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.filter_date_end}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom03">&nbsp;</Label>
                              <Button
                                style={{ width: "100%" }}
                                text="Filtrar"
                                isLoading={is_loading}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            { data.map((item) => 
              <Chart
                type={item.type}
                data={item.data}
              />
            ) }
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignsGoogleAnalyticsPage;