import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartLine2 = ({ data }) => {

  let labels = [];
  let values = [];
  for(let i = 0; i < data.values.length; i++){
    labels.push(data.values[i].label);
    values.push(data.values[i].value);
  }

  const LineColumnAreaData = {
    series: [
      {
        name: data.title,
        type: "line",
        data: values,
      },
    ],
    options: {
      chart: {
        height: 350,
          type: 'line',
          stacked: false,
          toolbar: {
              show: false
          },
      },
      stroke: {
        width: [2],
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          columnWidth: "0%",
        },
      },
      legend: {
        show: false,
    },
      colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],
  
      fill: {
        opacity: 1,
      },
      labels,
      markers: {
        size: 5,
      },
      xaxis: {
        type: "category",
        categories: labels,
        labels: {
          formatter: function(value) {
            try {
              const date = new Date(value);
              const day = date.getUTCDate();
              if(isNaN(day)){
                throw new Error("no number");
              }
              return `${day < 10 ? '0' + day : day}`;
            }
            catch(e){
              return value;
            }
          }
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            if(`${val}`.split('.').length > 1){
              return val.toFixed(2);
            }
            else {
              return val;
            }
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y + " " + data.unit;
            }
            return y;
          },
        },
        x: {
          formatter: function (x, opts) {
            try {
              return labels[opts.dataPointIndex];
            }
            catch(e){
              return x;
            }
          },
        }
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  }

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div>
              <ReactApexChart
                options={LineColumnAreaData.options}
                series={LineColumnAreaData.series}
                type="line"
                height="350"
                stacked= "false"
                className="apex-charts"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}