import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartPieBox1 = ({ data }) => {

  const ref = useRef();
  const [chatWidth, setChartWidth] = useState(50);

  let labels = [];
  let values = [];
  for(let i = 0; i < data.values.length; i++){
    labels.push(data.values[i].label);
    values.push(data.values[i].value);
  }

  const PieColumnAreaData = {
    series: values,
    options: {
      chart: {
        type: 'donut',
        parentHeightOffset: 0,
      },
      labels: labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      legend: {
        position: 'bottom',
      }
    },
  }

  useEffect(() => {
    if (ref.current) {
      setChartWidth(ref.current.offsetWidth - 1);
    }
  }, []);

  return (
    <Col xl={6} sm={12}>
      <Card>
        <CardBody>
          <div className="d-flex text-muted" ref={ref}>
            <div className="flex-shrink-0 me-3 align-self-center">
              <div id="radialchart-1" className="apex-charts" dir="ltr">
                <h5 style={{ color: data.color }}>{ data.title }</h5>
                <p style={{ color: data.color }}>{ data.description }</p>
                <ReactApexChart
                  options={PieColumnAreaData.options}
                  series={PieColumnAreaData.series}
                  type="donut"
                  width={chatWidth}
                  height="300"
                  stacked= "false"
                  className="apex-charts apex-charts-100"
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}