import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

export const ChartBarHorizontal1 = ({ data }) => {

  let values = [];
  let categories = [];
  for(let i = 0; i < data.values.length; i++){
    let v = [];
    for(let j = 0; j < data.values[i].values.length; j++){
      v.push(data.values[i].values[j].value);
      categories.push(data.values[i].values[j].label);
    }
    values.push({
      name: data.values[i].title,
      data: v,
    });
  }

  categories = categories.filter((item, index, self) => self.indexOf(item) === index);

  const cleanData = [];
  for(let i = 0; i < values.length; i++){
    if(values[i].data.length === categories.length){
      cleanData.push(values[i]);
    }
    else {
      let nn = values[i];
      let dd = nn.data;
      for(let j = dd.length; j < categories.length; j++){
        dd.push(0);
      }
      nn.data = dd;
      cleanData.push(nn);
    }
  }

  const LineColumnAreaData = {
    series: cleanData,
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              }
            }
          }
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: ""
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: function (val) {
            return val;
          },
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          formatter: function (val) {
            try {
              if(`${val}`.split('-').length === 3){
                const parts = val.split('-');
                const date = new Date(parts[0], parts[1] - 1, parts[2]);
                if(date instanceof Date && !isNaN(date)){
                  return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
                }
              }
            }
            catch(e){
              return val;  
            }
            return val;
          },
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      },
    }
  }

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div style={{ marginTop: 30 }}>
              <ReactApexChart
                options={LineColumnAreaData.options}
                series={LineColumnAreaData.series}
                type="bar"
                stacked= "false"
                className="apex-charts"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}