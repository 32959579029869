import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';

import { defaultTitle } from "../../constants/layout";
import { Loading } from '../../components/Common/Loading';

import API from "../../services/api";
import { BACKEND_UPLOADS } from '../../constants/env';
import { aAlert, formatDatetime, getUrlParam, removeUrlParam } from '../../helpers/general_helper';
import { NoResults } from '../../components/Common/NoResults';
import { HasPermission, PermissionOrDie } from '../../helpers/auth_helper';

import noAvatar from "../../assets/images/no-avatar.png";

const UsersPage = () => {
    document.title = `Usuários | ${defaultTitle}`;
    PermissionOrDie('users_list');

    const [modal_delete, setmodal_delete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
    const [modal_reset, setmodal_reset] = useState(false);
    const [resetId, setResetId] = useState(null);
    function tog_reset() {
        setmodal_reset(!modal_reset);
    }

    const [is_loading, setis_loading] = useState(true);
    const [myUserId, setMyUserId] = useState(null);

    const goNewUser = () => {
        window.location = '/users/create';
    }
    
    const goEditUser = (id) => {
        window.location = `/users/edit/${id}`;
    }

    const removeUser = (id) => {
        tog_delete();
        setDeleteId(id);
    }
    
    const resetUser = (id) => {
        tog_reset();
        setResetId(id);
    }

    const [data, setdata] = useState([]);
    useEffect(() => {
        API.get("users")
        .then(({ data }) => {
            setdata(data.list);
            setMyUserId(data.me);
        })
        .catch((error) => {
            aAlert(error?.response?.data?.message ?? error.message, "error");
        })
        .then(() => {
            setis_loading(false);
        });

        showSuccessMessages();
    }, []);

    const showSuccessMessages = () => {
        switch(getUrlParam('success')){
            case "1":
                aAlert("Usuário criado com sucesso!", "success");
            break;
            case "2":
                aAlert("Usuário editado com sucesso!<br><br>Recomendamos que o usuário saia e entre novamente no sistema para as alterações sejam aplicadas.", "success");
            break;
            case "3":
                aAlert("Usuário removido com sucesso!", "success");
            break;
            case "4":
                aAlert("Senha resetada com sucesso!<br><br>A nova senha foi enviada para o e-mail do usuário.", "success");
            break;
            default: break;
        }
        removeUrlParam("success");
    }

    const deleteIt = (id) => {
        setis_loading(true);
        tog_delete();
        setDeleteId(null);

        API.delete(`users/${id}`)
            .then(({ data }) => {
                window.location = '/users?success=3';
            })
            .catch((error) => {
                setis_loading(false);
                aAlert(error?.response?.data?.message ?? error.message, "error");
            });
    }
    
    const resetIt = (id) => {
        setis_loading(true);
        tog_reset();
        setResetId(null);

        API.put(`users/reset/${id}`)
            .then(({ data }) => {
                window.location = '/users?success=4';
            })
            .catch((error) => {
                setis_loading(false);
                aAlert(error?.response?.data?.message ?? error.message, "error");
            });
    }

    return (
        <React.Fragment>
            <Loading isLoading={is_loading} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Usuários" breadcrumbItem="Lista de Usuários" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Lista de Usuários</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        { HasPermission('users_create') &&
                                            <Row className="g-4 mb-3">
                                                <Col className="col-sm-auto">
                                                    <div className="d-flex gap-1">
                                                        <Button color="success" className="add-btn" onClick={() => goNewUser()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Novo Usuário</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="sort" data-sort="userAvatar" style={{ width: 45 }}></th>
                                                        <th className="sort" data-sort="name">Nome</th>
                                                        <th className="sort" data-sort="email">E-mail</th>
                                                        <th className="sort" data-sort="role">Permissão</th>
                                                        <th className="sort" data-sort="created">Criado Em</th>
                                                        { (HasPermission('users_edit') || HasPermission('users_delete') || HasPermission('users_reset')) && 
                                                            <th className="sort text-center" data-sort="action" width={270}>Ações</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    { data.map((data) => (
                                                    <tr key={data.id}>
                                                        <td className="userAvatar">
                                                            <img
                                                                className="rounded-circle header-profile-user"
                                                                src={data.avatar !== '' && data.avatar !== null ? `${BACKEND_UPLOADS}${data.avatar}` : noAvatar}
                                                                alt="user"
                                                                style={{
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#{ data.id }</Link></td>
                                                        <td className="name">{ data.name }</td>
                                                        <td className="email">{ data.username }</td>
                                                        <td className="role">{ data.roles[0].name }</td>
                                                        <td className="created">{ formatDatetime(data.created_at) }</td>
                                                        { (HasPermission('users_edit') || HasPermission('users_delete') || HasPermission('users_reset')) && 
                                                            <td>
                                                                <div className="d-flex gap-2 justify-content-center align-center">
                                                                    { HasPermission('users_edit') &&
                                                                        <div className="edit">
                                                                            <button className="btn btn-sm btn-success edit-item-btn" onClick={() => goEditUser(data.id)}>Editar</button>
                                                                        </div>
                                                                    }
                                                                    { HasPermission('users_reset') && data.id !== myUserId &&
                                                                        <div className="reset">
                                                                            <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => resetUser(data.id)}>Resetar Senha</button>
                                                                        </div>
                                                                    }
                                                                    { (HasPermission('users_delete') && data.id !== myUserId) &&
                                                                        <div className="remove">
                                                                            <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => removeUser(data.id)}>Remover</button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        }
                                                    </tr>
                                                    )) }
                                                </tbody>
                                            </table>
                                            { !is_loading && data.length === 0 &&
                                                <NoResults
                                                    title="Nenhum Resultado Encontrado!"
                                                    message="Não encontramos nenhum usuário cadastrado em nosso banco de dados."
                                                />
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn show" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Tem Certeza ?</h4>
                            <p className="text-muted mx-4 mb-0">Realmente deseja remover este usuário ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-danger " id="delete-record" onClick={() => deleteIt(deleteId)}>Sim, Remover Agora!</button>
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Cancelar</button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modal_reset} toggle={() => { tog_reset(); }} className="modal fade zoomIn show" id="resetRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_reset(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Tem Certeza ?</h4>
                            <p className="text-muted mx-4 mb-0">Realmente deseja resetar a senha deste usuário ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-warning " id="reset-record" onClick={() => resetIt(resetId)}>Sim, Resetar Agora!</button>
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_reset(false)}>Cancelar</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default UsersPage;
