import React, { useState } from 'react';


import logolight from '../../assets/images/logo-black.png';
import logodark from '../../assets/images/logo-black.png';

import { Container, Row, Col, Card, CardBody, Form, Alert, Label, Input, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';

import { defaultColors, defaultFooter, defaultTitle, defaultError } from "../../constants/layout";
import { Button } from '../../components/Common/Button';
import { useFormik } from 'formik';
import * as Yup from "yup";

import API from "../../services/api";
import { hexToRGBA } from '../../helpers/general_helper';

const RecoverPassword = () => {
    document.title = `Recuperar Senha | ${defaultTitle}`;

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState("");
    const [success, setSuccess] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
        email: Yup.string()
            .required("Por favor, informe seu e-mail")
            .email("Por favor, informe um e-mail válido"),
        }),
        onSubmit: (values) => {
            doRecover(values);
        },
    });

    const doRecover = (values) => {
        setIsLoading(true);
        setSuccess(false);
        API.post("recover-password", {
            username: values.email,
        })
        .then(({ data }) => {
            setError(false);
            setIsLoading(false);
            setSuccess(true);
        })
        .catch(({response}) => {
            setIsLoading(false);
            setError(response?.data?.message || defaultError);
        });
    };

    return (
        <React.Fragment>
            <div className="bg-pattern" style={{height:"100vh"}}>
                <div className="bg-overlay"></div>
                <div className="account-pages pt-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6} md={8} xl={4}>
                                <Card className='mt-5' style={{
                                    backgroundColor: hexToRGBA('#FFFFFF', 0.9),
                                }}>
                                    <CardBody className="p-4">
                                        <div className="mt-2">
                                            <div className="text-center">
                                                 <Link to="/">
                                                    <img
                                                    src={logodark}
                                                    alt=""
                                                    height="56.11"
                                                    className="auth-logo logo-dark mx-auto"
                                                    />
                                                    <img
                                                    src={logolight}
                                                    alt=""
                                                    height="56.11"
                                                    className="auth-logo logo-light mx-auto"
                                                    />
                                                </Link>
                                            </div>
                                            <h4 className="font-size-18 text-black mt-4 text-center">Recuperar Senha</h4>
                                            <p className="mb-4 text-muted text-center">Esqueceu sua senha? Nós te ajudaremos com isso :)</p>
                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                {error ? (
                                                    <Alert color="danger">
                                                    <div>{error}</div>
                                                    </Alert>
                                                ) : null}
                                                <Row>
                                                    <Col md={12}>
                                                        { !success ?
                                                            <div className="alert alert-warning alert-dismissible">
                                                                Informe seu <b>e-mail</b> para receber uma nova senha!
                                                            </div>
                                                        :
                                                            <div className="alert alert-info alert-dismissible">
                                                                Se você informou os dados corretamente, você receberá um e-mail com uma nova senha.<br /><br />
                                                                <b><a href="/login">[Fazer Login]</a></b>
                                                            </div>
                                                        }

                                                        <div className="mt-3">
                                                            <Label className="form-label text-black">
                                                                E-mail
                                                            </Label>
                                                            <Input
                                                                name="email"
                                                                className="form-control text-black"
                                                                placeholder="Informe seu e-mail"
                                                                type="email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ""}
                                                                invalid={
                                                                    validation.touched.email &&
                                                                    validation.errors.email
                                                                    ? true
                                                                    : false
                                                                }
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    borderColor: "#53385A",
                                                                }}
                                                            />
                                                            {validation.touched.email &&
                                                            validation.errors.email ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{validation.errors.email}</div>
                                                            </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="d-grid mt-4">
                                                            <Button
                                                                style={{
                                                                    backgroundColor: defaultColors.PINK,
                                                                }}
                                                                text="Recuperar Senha"
                                                                isLoading={isLoading}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p className="text-white-50">Lembrou sua senha ?  <Link to="/login" className="fw-medium text-primary"> Fazer Login  </Link> </p>
                                    <p className="text-white-50">
                                        { defaultFooter }
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </React.Fragment>
    );
}

export default RecoverPassword;