import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import { BACKEND_UPLOADS } from "../../constants/env";
import { getLoggedinUser } from "../../helpers/api_helper";

const Breadcrumbs = (props) => {

  const [logo, setLogo] = useState("");

  useEffect(() => {
    if (getLoggedinUser()) {
      const obj = getLoggedinUser();
      if(obj.company.logo){
        setLogo(`${BACKEND_UPLOADS}${obj.company.logo}`);
      }
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            {/* <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4> */}
            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                <BreadcrumbItem>
                  <Link to="#">{props.title}</Link>
                </BreadcrumbItem>
                {props.breadcrumbMother &&
                  <BreadcrumbItem>
                    <Link to="#">{props.breadcrumbMother}</Link>
                  </BreadcrumbItem>
                }
                <BreadcrumbItem active>
                  {/* <Link to="#"> */}
                    {props.breadcrumbItem}
                    {/* </Link> */}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            { logo && 
              <img src={logo} width="auto" height={27} alt="Customer" id="customer-logo" />
            }
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}



export default Breadcrumbs;
