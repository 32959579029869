import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import DataTable from 'react-data-table-component';
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

export const ChartTable = ({ data }) => {

  const selectLayoutState = (state) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutModeTypes: layout.layoutModeTypes,
      leftSideBarType: layout.leftSideBarType,
      layoutWidth: layout.layoutWidth,
      topbarTheme: layout.topbarTheme,
      showRightSidebar: layout.showRightSidebar,
      leftSideBarTheme: layout.leftSideBarTheme,
    })
  );

  const { layoutModeTypes } = useSelector(selectLayoutProperties);

  let columns = [];
  for(let i = 0; i < data.header.length; i++){
    let headerTxt = data.header[i];
    let width = null;
    if(headerTxt.includes("[w=")){
      const s = headerTxt.split("[w=");
      width = s[1].replace("]", "");
      headerTxt = s[0];
    }

    let column = {
      name: <span className='font-weight-bold fs-13'>{headerTxt}</span>,
      selector: row => row[`r-${i}`],
      sortable: true,
      cell: row => <div dangerouslySetInnerHTML={{ __html: row[`r-${i}`] }} style={{ minWidth: 100 }} />,
    };

    if(width !== null){
      column = {
        ...column,
        width,
      };
    }

    columns.push(column);
  }

  let content = [];
  for(let i = 0; i < data.content.length; i++){
    let row = {};
    for(let j = 0; j < data.header.length; j++){
      row = {
        ...row,
        [`r-${j}`]: data.content[i][j]
      }
    }
    content.push(row);
  }

  const customDarkStyles = {
    headCells: {
      style: {
        backgroundColor: '#2d3448',
        color: '#79859c',
        fontSize: '14px',
        borderColor: '#2d3448 !important',
      }
    },
    cells: {
      style: {
        backgroundColor: '#252b3b',
        color: '#79859c',
        fontSize: '14px',
        borderColor: '#2d3448 !important',
      }
    },
    headRow: {
      style: {
        borderColor: '#2d3448 !important',
      }
    },
    rows: {
      style: {
        borderColor: '#2d3448 !important',
      },
    },
    pagination: {
      style: {
        backgroundColor: '#252b3b',
        color: '#FFF',
      },
      buttonStyle: {
        backgroundColor: '#2d3448',
        color: '#FFF',
      }
    },
    noData: {
      style: {
        backgroundColor: '#252b3b',
        color: '#79859c',
      }
    }
  };

  return (
    <Col xl={12} sm={12}>
      <Card>
        <CardBody>
          <div>
            <div>
                <h5 className="card-title">
                  { data.title }
                </h5>
            </div>
            <div className="table-responsive table-card mt-3 mb-1">
              <DataTable
                  columns={columns}
                  data={content}
                  pagination
                  noDataComponent={<>Nenhum registro encontrado.</>}
                  customStyles={layoutModeTypes === 'dark' ? customDarkStyles : undefined}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}