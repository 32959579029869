import React from "react";
import { Col } from "reactstrap";

export const ChartTitle = ({ data }) => {
  return (
    <Col xl={12} sm={12}>
      <div className="mt-3 mb-4">
        <h5>{ data.title }</h5>
      </div>
    </Col>
  );
}