import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';

import { defaultTitle } from "../../constants/layout";
import { Loading } from '../../components/Common/Loading';

import API from "../../services/api";
import { aAlert, formatDate, formatDatetime, getUrlParam, goalFormat, removeUrlParam } from '../../helpers/general_helper';
import { NoResults } from '../../components/Common/NoResults';
import { HasPermission, PermissionOrDie } from '../../helpers/auth_helper';

const GoalsPage = () => {
    document.title = `Metas | ${defaultTitle}`;
    PermissionOrDie('permissions_list'); // TODO

    const [modal_delete, setmodal_delete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }

    const [is_loading, setis_loading] = useState(true);

    const goNewGoal = () => {
        window.location = '/goals/create';
    }
    
    const goEditGoal = (id) => {
        window.location = `/goals/edit/${id}`;
    }

    const removeGoal = (id) => {
        tog_delete();
        setDeleteId(id);
    }

    const [data, setdata] = useState([]);
    useEffect(() => {
        API.get("goals")
        .then(({ data }) => {
            setdata(data);
        })
        .catch((error) => {
            aAlert(error?.response?.data?.message ?? error.message, "error");
        })
        .then(() => {
            setis_loading(false);
        });

        showSuccessMessages();
    }, []);

    const showSuccessMessages = () => {
        switch(getUrlParam('success')){
            case "1":
                aAlert("Meta criada com sucesso!", "success");
            break;
            case "2":
                aAlert("Meta editada com sucesso!", "success");
            break;
            case "3":
                aAlert("Meta removida com sucesso!", "success");
            break;
            default: break;
        }
        removeUrlParam("success");
    }

    const deleteIt = (id) => {
        setis_loading(true);
        tog_delete();
        setDeleteId(null);

        API.delete(`goals/${id}`)
            .then(({ data }) => {
                window.location = '/goals?success=3';
            })
            .catch((error) => {
                setis_loading(false);
                aAlert(error?.response?.data?.message ?? error.message, "error");
            });
    }

    return (
        <React.Fragment>
            <Loading isLoading={is_loading} />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Metas" breadcrumbItem="Lista de Metas" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Lista de Metas</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        { HasPermission('permissions_create') &&
                                            <Row className="g-4 mb-3">
                                                <Col className="col-sm-auto">
                                                    <div className="d-flex gap-1">
                                                        <Button color="success" className="add-btn" onClick={() => goNewGoal()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Nova Meta</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="sort" data-sort="title">Descrição</th>
                                                        <th className="sort" data-sort="stage">Etapa do Funil</th>
                                                        <th className="sort" data-sort="range">Período</th>
                                                        <th className="sort" data-sort="goal">Meta</th>
                                                        <th className="sort" data-sort="created">Criado Em</th>
                                                        { (HasPermission('permissions_edit') || HasPermission('permissions_delete')) && 
                                                            <th className="sort text-center" data-sort="action" width={200}>Ações</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    { data.map((data) => (
                                                    <tr key={data.id}>
                                                        <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#{ data.id }</Link></td>
                                                        <td className="title">{ data.title !== "" ? data.title : "-" }</td>
                                                        <td className="stage">{ data.funnel_stage_formatted }</td>
                                                        <td className="range">{ formatDate(data.range_date_start) } - { formatDate(data.range_date_end) }</td>
                                                        <td className="goal">{ goalFormat(data.funnel_stage, data.goal) }</td>
                                                        <td className="created">{ formatDatetime(data.created_at) }</td>
                                                        { (HasPermission('permissions_edit') || HasPermission('permissions_delete')) && 
                                                            <td>
                                                                <div className="d-flex gap-2 justify-content-center align-center">
                                                                    { HasPermission('permissions_edit') &&
                                                                        <div className="edit">
                                                                            <button className="btn btn-sm btn-success edit-item-btn" onClick={() => goEditGoal(data.id)}>Editar</button>
                                                                        </div>
                                                                    }
                                                                    { (HasPermission('permissions_delete') && data.name !== 'Super Admin') &&
                                                                        <div className="remove">
                                                                            <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => removeGoal(data.id)}>Remover</button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        }
                                                    </tr>
                                                    )) }
                                                </tbody>
                                            </table>
                                            { !is_loading && data.length === 0 &&
                                                <NoResults
                                                    title="Nenhum Resultado Encontrado!"
                                                    message="Não encontramos nenhuma meta cadastrada em nosso banco de dados."
                                                />
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn show" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Tem Certeza ?</h4>
                            <p className="text-muted mx-4 mb-0">Realmente deseja remover esta meta ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-danger " id="delete-record" onClick={() => deleteIt(deleteId)}>Sim, Remover Agora!</button>
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Cancelar</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default GoalsPage;
