import React, { useEffect } from "react";

export const Loading = ({ isLoading }) => {

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return `${r}, ${g}, ${b}`;
  };

  useEffect(() => {
    if(document.getElementById("dots") !== null){
      setInterval(() => {
        if(document.getElementById("dots") !== null){
          const d = document.getElementById("dots");
          switch(d.innerText){
            case ".  ": d.innerHTML = "..&nbsp;"; break;
            case ".. ": d.innerHTML = "..."; break;
            default: d.innerHTML = ".&nbsp;&nbsp;"; break;
          }
        }
      }, 500);
    }
  }, []);
  
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `rgba(${hexToRgb('#000000')}, 0.3)`,
        zIndex: 99999,
        display: isLoading ? 'flex' : 'none',
      }}
      className="loadingContainer"
    >
      <h3 style={{
        color: '#000',
      }}>
        Carregando{" "}
        <span id="dots">.&nbsp;&nbsp;</span>
      </h3>
    </div>
  );

}