
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "./rightbar.scss";

//constants
import {
  layoutModeTypes,
} from "../../constants/layout";

import API from "../../services/api";
import { aAlert } from "../../helpers/general_helper";
import { getLoggedinUser, setLoggedinUser } from "../../helpers/api_helper";

const RightSidebar = props => {

  const changeLayoutMode = (mode) => {
    const user = getLoggedinUser();
    if(user){
      user.layout_mode = mode;
      setLoggedinUser(user);
    }

    API.post("users/layout", {
      layout: mode,
    })
    .then(({ data }) => {})
    .catch(({ error }) => {
      aAlert(error?.response?.data?.message ?? error.message, "error");
    });
  }

  return (
    <React.Fragment>
      <div className="right-bar" id="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  props.showRightSidebarAction(false);
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Configurações</h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              {/* Layout Mode */}
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Modo</span>
                <input
                  type="radio"
                  id="radioLightmode"
                  name="radioMode"
                  value={layoutModeTypes.LIGHTMODE}
                  checked={props.layoutModeTypes === layoutModeTypes.LIGHTMODE}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value);
                      changeLayoutMode('light');
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioLightmode">Claro</label>

                <input
                  type="radio"
                  id="radioDarkMode"
                  name="radioMode"
                  value={layoutModeTypes.DARKMODE}
                  checked={props.layoutModeTypes === layoutModeTypes.DARKMODE}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value);
                      changeLayoutMode('dark');
                    }
                  }}
                />
                <label htmlFor="radioDarkMode">Escuro</label>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  );
};

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutMode: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  layoutType: PropTypes.any,
  layoutModeTypes: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any,
  onClose: PropTypes.func,
};

const mapStateToProps = state => {
  return { ...state.Layout };
};

export default connect(mapStateToProps, {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  showRightSidebarAction,
})(RightSidebar);
